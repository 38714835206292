import React from 'react'
import styled from 'styled-components'

import { H5, LargeP } from 'src/components/shared/text'
import { colors, fonts } from 'src/styles/variables'
import { media } from 'src/styles/util'

import navyBackgroundVector from 'src/assets/img/product-library/navy-background-vector.svg'
import pinkBackgroundVector from 'src/assets/img/product-library/pink-background-vector.svg'

const ContactUsContainer = styled.div`
  background-color: ${colors.orange};

  position: relative;
  overflow: hidden;
  z-index: 1;

  display: grid;
  place-items: center;

  min-height: 300px;

  ${media.mobile`
    padding: 50px 16px 60px 16px;
  `}
  ${media.tablet`
    padding: 50px 20px 60px 20px;
  `}
  ${media.desktop`
    padding: 75px 60px 75px 60px;
  `}
`

const NavyVectorImg = styled.img`
  z-index: -1;

  position: absolute;
  top: 0;
  left: 0;

  ${media.mobile`
    width: 200vw;
  `}
  ${media.tablet`
    width: 60vw;
  `}
  ${media.desktop`
    height: 300px;
    width: auto;
  `}
`

const PinkVectorImg = styled.img`
  z-index: -2;

  position: absolute;
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;

  ${media.mobile`
    width: 200vw;
  `}
  ${media.tablet`
    width: 80vw;
    right: -100px;
  `}
  ${media.desktop`
    width: auto;
    height: 100%;
    object-fit: cover;
    right: 0;
  `}
`

const ContactUsTextContainer = styled.div`
  background-color: white;
  border-radius: 25px;

  display: grid;
  place-items: center;
  grid-row-gap: 16px;

  text-align: center;
  color: ${colors.navy};

  ${media.mobile`
    padding: 36px 20px 40px 20px;
  `}
  ${media.tablet`
    padding: 36px 40px 40px 40px;
    max-width: 550px;
  `}
  ${media.desktop`
    padding: 47px 60px 37px 60px;
    max-width: 800px;
  `}

  & a {
    font-family: ${fonts.larsseit.medium};
    color: ${colors.orange};

    &:hover {
      text-decoration: underline;
    }
  }
`

const Contact = () => (
  <ContactUsContainer>
    <ContactUsTextContainer>
      <H5>Don’t see the role that’s perfect for you?</H5>
      <LargeP>
        We’re always looking to meet interesting people. Drop us a line at{' '}
        <a href="mailto:hello@petfinn.com">hello@petfinn.com</a> and let us know how you can help!
      </LargeP>
    </ContactUsTextContainer>
    <NavyVectorImg src={navyBackgroundVector} aria-hidden="true" />
    <PinkVectorImg src={pinkBackgroundVector} aria-hidden="true" />
  </ContactUsContainer>
)

export default Contact
