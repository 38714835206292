import React from 'react'
import styled from 'styled-components'

import { SmallSecondaryButton } from 'src/components/shared/buttons'
import { LargeP, MonoP } from 'src/components/shared/text'
import { colors, fonts } from 'src/styles/variables'
import { media } from 'src/styles/util'

const Row = styled.a`
  width: 100%;
  border-top: 1px solid ${colors.lightGrey};

  text-align: left;

  display: grid;
  grid-gap: 20px;
  padding: 40px 0;

  &:first-of-type {
    border: none;
  }

  ${media.tablet`
    grid-template-columns: auto auto;
    justify-content: space-between;
    padding: 30px 20px;
  `}
`

const JobPosition = styled(LargeP)`
  font-size: 24px;
  font-family: ${fonts.larsseit.medium};
`

const JobDetailsP = styled(MonoP)`
  color: ${colors.slate};
  margin-top: 12px;
`

interface FormattedJobInterface {
  company: string
  position: string
  commitment: string
  location: string
  team: string
  applyUrl: string
}

const JobListingRow = ({ job }: { job: FormattedJobInterface }) => {
  return (
    <Row href={job.applyUrl} target="_blank" rel="noopener noreferrer">
      <div>
        <JobPosition>{job.position}</JobPosition>
        <JobDetailsP>
          {job.team} | {job.commitment} | {job.location}
        </JobDetailsP>
      </div>
      <SmallSecondaryButton copy="Apply Now" arrow />
    </Row>
  )
}

export default JobListingRow
