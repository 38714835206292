import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import Careers from 'src/components/Careers'
import SEO from 'src/components/shared/SEO'

const SEO_CONFIG = {
  title: 'Careers | Finn',
  description:
    'Come join us! Finn formulates premium dog supplements and vitamins that combine trusted research and modern wellness.',
  keywords: ['Careers', 'Join Us']
}

const CareersPage: React.FC<PageProps> = ({ location }) => {
  const { title, description, keywords } = SEO_CONFIG
  return (
    <>
      <SEO title={title} description={description} keywords={keywords} location={location} />

      <Layout location={location}>
        <Careers />
      </Layout>
    </>
  )
}

export default CareersPage
