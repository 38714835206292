import { useState, useEffect } from 'react'
import axios from 'axios'

interface LeverJobInterface {
  additional: string
  additionalPlain: string
  applyUrl: string
  categories: {
    commitment: string
    department: string
    location: string
    team: string
  }
  createdAt: string
  description: string
  descriptionPlain: string
  hostedUrl: string
  id: string
  lists: Array<{
    content: string
    text: string
  }>
  text: string
}

interface FormattedJobInterface {
  company: string
  position: string
  commitment: string
  location: string
  team: string
  applyUrl: string
}

const useLever = () => {
  const [loading, setLoading] = useState(true)
  const [currentJobListings, setCurrentJobListings] = useState([])

  const fetchLeverData = async () => {
    try {
      const leverEndpoint = 'https://api.lever.co/v0/postings/dojomojo?mode=json'
      const { data } = await axios.get(leverEndpoint)

      const filteredJobListings = data
        .map((job: LeverJobInterface) => ({
          company: job.categories.department,
          position: job.text,
          commitment: job.categories.commitment,
          location: job.categories.location,
          team: job.categories.team,
          applyUrl: job.hostedUrl
        }))
        .filter((formattedJob: FormattedJobInterface) => formattedJob.company.toLowerCase() === 'finn wellness')

      setCurrentJobListings(filteredJobListings)
    } catch (err) {
      throw new Error('Job Listing fetch error: ', err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchLeverData()
  }, [])

  return [loading, currentJobListings]
}

export default useLever
