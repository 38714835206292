import React from 'react'
import styled from 'styled-components'

import DropHeader from 'src/components/shared/DropHeader'
import Spinner from 'src/components/shared/Spinner'
import { LargeP } from 'src/components/shared/text'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'

import useLever from './useLever'
import JobListingRow from './JobListingRow'
import ContactUsTile from './ContactUsTile'

const Section = styled.section`
  background-color: white;

  width: 100%;
  min-height: 100vh;

  display: grid;
  grid-template-rows: auto 1fr auto;

  ${media.mobile`
    min-height: calc(100vh - 63px);
    padding-top: 30px;
  `}
  ${media.tablet`
    padding-top: 50px;
  `}
  ${media.desktop`
    min-height: 100vh;
    padding-top: 0;
  `}
`

const ListingsGrid = styled.div`
  position: relative;

  width: 100%;
  max-width: 900px;

  display: grid;
  place-items: center;
  text-align: center;

  margin: 0 auto;

  ${media.mobile`
    min-height: 225px;
    padding: 10px 30px 75px 30px;
  `}
  ${media.tablet`
    padding: 50px 50px;
  `}
  ${media.desktop`
    min-height: 300px;
  `}
`

const NoPositionsP = styled(LargeP)`
  color: ${colors.slate};
  white-space: pre-line;
`

interface FormattedJobInterface {
  company: string
  position: string
  commitment: string
  location: string
  team: string
  applyUrl: string
}

const NoJobsControllerComponent = ({ jobListings }: { jobListings: FormattedJobInterface[] }) =>
  jobListings?.length === 0 ? (
    <NoPositionsP>{`We don't have any openings right now,\nbut check back soon!`}</NoPositionsP>
  ) : (
    <>
      {jobListings?.map((job: FormattedJobInterface) => (
        <JobListingRow job={job} />
      ))}
    </>
  )

const LoadingControllerComponent = ({ loading, jobListings }: { loading: boolean; jobListings: FormattedJobInterface[] }) => {
  return loading ? <Spinner size={75} color={colors.orange} /> : <NoJobsControllerComponent jobListings={jobListings} />
}

const Careers = () => {
  const [loading, jobListings] = useLever()

  return (
    <Section>
      <DropHeader title={`Current\nOpportunities`} isPageHeader fontSize={120} tabletFontSize={88} mobileFontSize={54} />

      <ListingsGrid>
        <LoadingControllerComponent loading={loading} jobListings={jobListings} />
      </ListingsGrid>

      <ContactUsTile />
    </Section>
  )
}

export default Careers
